import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "../../firebase-config";
import store from "@/store";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/App"),
    children: [
      {
        path: "",
        name: "main",
        component: () => import("@/views/Customer/Main"),
        beforeEnter: clearState,
      },
      {
        path: "/register-form",
        name: "register",
        component: () => import("@/views/Customer/Register"),
        meta: { requiresAuth: false },
        beforeEnter: checkPhoneUser,
      },
      {
        path: "/thank",
        name: "thanks",
        component: () => import("@/views/Customer/Thank"),
        meta: { requiresAuth: true },
        beforeEnter: checkPhoneUser,
      },

      {
        path: "/menu",
        name: "menu",
        component: () => import("@/views/Customer/Menu"),
        meta: { requiresAuth: true },
        beforeEnter: checkPhoneUser,
      },
      {
        path: "/luckydraw",
        name: "luckydraw",
        component: () => import("@/views/Customer/Luckydraw"),
        meta: { requiresAuth: true },
        redirect: { name: "main" },
        beforeEnter: checkPhoneUser,
      },
      {
        path: "/history",
        name: "history",
        component: () => import("@/views/Customer/History"),
        meta: { requiresAuth: true },
        beforeEnter: checkPhoneUser,
      },
      {
        path: '/condition',
        name: 'condition',
        component: () => import("@/views/Customer/Condition"),
        meta: { requiresAuth: true },
        beforeEnter: checkPhoneUser,
      },
      {
        path: '/winner',
        name: 'winner',
        component: () => import("@/views/Customer/Winner"),
        meta: { requiresAuth: true },
        beforeEnter: checkWinner,
      },
      {
        path: "login-admin",
        name: "login",
        component: () => import("@/views/Admin/Login"),
      },
      {
        path: "/admin",
        name: "admin",
        component: () => import("@/views/Admin/Layout"),
        beforeEnter: checkExpireAdmin,
        redirect: { name: 'login' },
        children: [

          {
            path: "report-admin",
            name: "report",
            beforeEnter: checkExpireAdmin,
            component: () => import("@/views/Admin/Report"),
          },
          {
            path: "dashboard",
            name: "dashboard",
            beforeEnter: checkExpireAdmin,
            component: () => import("@/views/Admin/Dashboard"),
          }
        ]
      },
    ]
  },


  {
    path: "*",
    component: () => import("@/views/NotFound"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash };
    }
    return { x: 0, y: 0 };
  },
});


function checkPhoneUser(to, from, next) {
  const storeUser = store.state.user
  if (!storeUser.phone && to.meta.requiresAuth) {
    next({ name: "main" });
    return;
  }
  next();
}


function checkWinner(to, from, next) {
  const storeUser = store.state.user
  if (!storeUser.phone && to.meta.requiresAuth) {
    next({ name: "main" });
    return;
  }
  const currentDate = new Date();
  const targetDate = new Date("2024-03-13T16:00:00");

  if (currentDate.getTime() <= targetDate.getTime()) {
    next({ name: "main" });
    return
  }

  next();
}

function clearState(to, from, next) {
  store.commit("setUser", { name: "", phone: "", birthdate: "", gender: "", email: '' });
  next();
}

function checkExpireAdmin(to, from, next) {
  auth.onAuthStateChanged((user) => {
    if (!user) {
      next({ name: "login" });
      return;
    }
  });
  next();
}

const DEFAULT_TITLE = "Kool Kool Campaign";
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
