import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);
const theme = {
  primary: "#1e88e5", // change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
  info: "#1e88e5",
  success: "#008556",
  accent: "#fc4b6c",
  default: "#563dea",
  bg: "#66ff66",
  bgButton: "#66ff66",
  textButton: "#873e23",
  border: "#66ff66",
  pink: "#e21f9e"
};

export default new Vuetify({
  icons: {
    defaultSet: "mdi",
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
