<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  head: {
    link: [
      {
        rel: "icon",
        href: require("./assets/logo_shop/coolKey-02.png"),
        sizes: "16x16",
        type: "image/jpg",
      },
    ],
  },
  data() {
    return {};
  },
};
</script>

<style>
.v-main {
  background-image: url("../public/img/image/bg_Ver.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-color: #fff;
  background-blend-mode: multiply;
  background-size: cover;
}

@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", "Prompt" !important;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
