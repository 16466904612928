import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDF4HoLU5gW8hIQa_94zJqB0H_27nOgM2Q",
  authDomain: "fresh-cool-4af9d.firebaseapp.com",
  projectId: "fresh-cool-4af9d",
  storageBucket: "fresh-cool-4af9d.appspot.com",
  messagingSenderId: "40621323262",
  appId: "1:40621323262:web:1d0e4772b506d7abc386af"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore;
const db = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();


export { db, firestore, auth, storage };
