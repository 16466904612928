import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    typeRegister: null,
    user: { name: "", phone: "", birthdate: "", gender: "" },
    admin: "",
  },
  getters: {},
  mutations: {
    setType(state, payload) {
      state.typeRegister = payload;
    },
    setUser(state, payload) {
      state.user.name = payload.name;
      state.user.phone = payload.phone;
      state.user.birthdate = payload.birthdate;
      state.user.gender = payload.gender;
      state.user.email = payload.email ?? "";
    },
    setAdmin(state, payload) {
      if (payload == "admin@koolkool.com") {
        state.admin = "admin";
      } else {
        state.admin = "guess";
      }
    },
  },
  actions: {},
});
